<template>
  <div class="pcm-app">
    <div class="header">
      <div class="title" @click="doCopy">永久地址 {{ addressUrl }}</div>
      <div class="tip">温馨提示:ms越小,反应时间越小,打开就越快</div>
    </div>
    <div class="subTitle">— 线路检测 —</div>
    <div v-if="showImg">
      <img
        v-for="(item, index) in urlList"
        :key="index"
        :src="item.url"
        width="1"
        height="1"
        @error="autoTest(item, index)"
        style="display: none"
      />
    </div>
    <div class="domainNameBox" v-for="item in urlList" :key="item.url">
      <div class="appBox">
        <div class="appCover">
          <img :src="item.icon" class="appIcon" />
          <div class="hot"></div>
        </div>
        <div class="appName">{{ item.name }}</div>
      </div>
      <div class="timeConsuming">
        <span v-if="item.time == 0">检测中...</span
        ><span v-else
          ><span v-if="item.time < 50" class="green">{{ item.time }}ms</span>
          <span v-if="item.time >= 50 && item.time < 100" class="orange"
            >{{ item.time }}ms</span
          >
          <span v-if="item.time >= 100" class="red"
            >{{ item.time }}ms</span
          ></span
        >
      </div>
      <!-- <div class="domainName">{{ item.url }}</div> -->
      <div class="intoBtn" @click="jumpUrl(item)">立即进入</div>
    </div>
    <div class="resetBox" @click="reset">
      <span>点击这里</span>重新检测刷新访问速度
    </div>
    <div class="tgBox" @click="jumpUrl2('https://t.me/gltwsh')">
      <div class="tg"></div>
      <span>客服TG @gltwsh</span>
    </div>
    <div class="sizeBox"></div>
  </div>
</template>
<script>
import { mixins } from "./../../../mixin/index";

export default {
  name: "pc",
  mixins: [mixins],
};
</script>
<style lang="scss" scoped>
.pcm-app {
  height: 100%;
  overflow-y: auto;
  background: url("./../../../assets/png/pcBg.jpg") no-repeat;
  background-size: 100% 100%;
  .header {
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    padding: 17px 0;
    .title {
      font-size: 36px;
      font-weight: 800;
    }
    .tip {
      opacity: 0.8;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
  .subTitle {
    font-size: 36px;
    font-weight: 800;
    text-align: center;
    margin: 30px 0 18px;
  }
  .domainNameBox {
    height: 86px;
    width: 924px;
    padding: 0 12px;
    border: 1px rgba(255, 255, 255, 0.2) solid;
    border-radius: 12px;
    margin: 16px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.2);
    .appBox {
      display: flex;
      align-items: center;
      .appCover {
        position: relative;
        height: 50px;
        width: 50px;
        .appIcon {
          height: 50px;
          width: 50px;
          border-radius: 4px;
          overflow: hidden;
        }
        .hot {
          position: absolute;
          right: -5px;
          top: -10px;
          height: 15px;
          width: 15px;
          background: url("./../../../assets/png/hot.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .appName {
        font-size: 20px;
        font-weight: 500;
        margin-left: 15px;
        width: 90px;
      }
    }
    .timeConsuming {
      color: skyblue;
      font-size: 24px;
      font-weight: 800;
    }
    .green {
      color: #00cf46;
    }
    .orange {
      color: #f5a937;
    }
    .red {
      color: #e93333;
    }
    .domainName {
      // width: 100px;
      word-wrap: break-word;
    }
    .intoBtn {
      height: 48px;
      width: 128px;
      border-radius: 48px;
      color: rgba(51, 51, 51, 1);
      background: rgba(245, 169, 55, 1);
      text-align: center;
      line-height: 48px;
      cursor: pointer;
    }
  }
  .turnPageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #2bdef9;
      font-size: 16px;
    }
    .downArrow {
      height: 16px;
      width: 16px;
      background: url("./../../../assets/png/downArrow.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 6px;
    }
  }
  .resetBox {
    width: 343px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px rgba(255, 255, 255, 0.2) solid;
    border-radius: 12px;
    margin: 16px auto;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    cursor: pointer;
    span {
      color: #2bdef9;
      margin-right: 4px;
    }
  }
  .tgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    font-size: 24px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    cursor: pointer;
    .tg {
      height: 34px;
      width: 34px;
      background: url("./../../../assets/png/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 12px;
    }
  }
  .sizeBox {
    height: 100px;
  }
}
</style>
