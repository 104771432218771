import { Toast } from "vant";
import { getDevType } from "@/utils/index";

export const mixins = {
  data() {
    return {
      urlList: [
        {
          url: "https://d36wm9w0y4qg64.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://d36wm9w0y4qg64.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-haose.png"),
          name: "好色",
          time: 0,
        },
        {
          url: "https://d1kzz9xnjye9q.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://d1kzz9xnjye9q.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-haijiao.png"),
          name: "海角",
          time: 0,
        },
        {
          url: "https://di04nxs2rptud.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://di04nxs2rptud.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-Pornhub.png"),
          name: "PornHub",
          time: 0,
        },
        {
          url: "https://d353ew6vaotihc.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://d353ew6vaotihc.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-lld.jpg"),
          name: "萝莉岛",
          time: 0,
        },
        {
          url: "https://d36hmk18ln4ke2.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://d36hmk18ln4ke2.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-yjmh.png"),
          name: "妖精动漫",
          time: 0,
        },
        {
          url: "https://d28xb20xzkly77.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://d28xb20xzkly77.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-lieqi.png"),
          name: "91猎奇",
          time: 0,
        },
        {
          url: "https://d16nh5rs4my36s.cloudfront.net?dc=LSCGW01",
          iosUrl: "https://d16nh5rs4my36s.cloudfront.net?dc=LSCGW01",
          icon: require("./../assets/png/logo-pf.png"),
          name: "泡芙",
          time: 0,
        },

        // {
        //   url: "https://d1d4abgkiknem8.cloudfront.net/?dc=LSCGW01",
        //   iosUrl: "https://d1d4abgkiknem8.cloudfront.net/?dc=LSCGW01",
        //   icon: require("./../assets/png/logo-nut.png"),
        //   name: "NUT",
        //   time: 0,
        // },
        // {
        //   url: "https://d3qqs1aiqzu3j7.cloudfront.net?dc=LSCGW01",
        //   iosUrl: "https://d3qqs1aiqzu3j7.cloudfront.net?dc=LSCGW01",
        //   icon: require("./../assets/png/logo-bbbs.png"),
        //   name: "宝宝巴士",
        //   time: 0,
        // },
        
        // {
        //   url: "https://d3hsovndf8g2cr.cloudfront.net/?dc=LSCGW01",
        //   iosUrl: "https://d3hsovndf8g2cr.cloudfront.net/?dc=LSCGW01",
        //   icon: require("./../assets/png/logo-hskt.gif"),
        //   name: "好色课堂",
        //   time: 0,
        // },
        
        // {
        //   url: "https://d1zb7hqqwax77r.cloudfront.net?dc=LSCGW01",
        //   iosUrl: "https://d1zb7hqqwax77r.cloudfront.net?dc=LSCGW01",
        //   icon: require("./../assets/png/logo-qiyou.png"),
        //   name: "妻友",
        //   time: 0,
        // },
        // {
        //   url: "https://d36finu03qltb7.cloudfront.net?dc=LSCGW01",
        //   iosUrl: "https://d36finu03qltb7.cloudfront.net?dc=LSCGW01",
        //   icon: require("./../assets/png/logo-anwang.png"),
        //   name: "91暗网",
        //   time: 0,
        // },
        // {
        //   url: "https://d54pimh36uyyb.cloudfront.net/?dc=LSCGW01",
        //   iosUrl: "https://d54pimh36uyyb.cloudfront.net/?dc=LSCGW01",
        //   icon: require("./../assets/png/logo-naisi.png"),
        //   name: "奶思动漫",
        //   time: 0,
        // },
      ], // 域名数组
      ping: 1,
      timer: null,
      showImg: true,
      addressUrl: "9527cc.com",
    };
  },
  mounted() {
    this.addressUrl = window.location.host;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        this.ping++;
      }, 100);
    },
    autoTest(item, index) {
      if (this.ping <= 10) {
        item.time = this.ping * 10;
      } else {
        item.time = this.ping * 100;
      }
      if (index == this.urlList.length - 1) {
        clearInterval(this.timer);
      }
    },
    jumpUrl(item) {
      if (getDevType() == "android") {
        window.open(item.url);
      } else {
        window.open(item.iosUrl);
      }
    },
    jumpUrl2(url) {
      window.open(url);
    },
    reset() {
      this.urlList = this.urlList.map((item) => {
        item.time = 0;
        return item;
      });
      this.showImg = false;
      this.$nextTick(() => {
        this.showImg = true;
      });
      this.ping = 1;
      this.init();
    },
    doCopy() {
      let req = this.addressUrl;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
