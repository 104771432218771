/**
 *  截取url参数
 * @param {*} name 截取url参数名称
 * @returns
 */
export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substring(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

/**
 * 获取设备类型
 * @returns {string}  //设备类型
 */
export function getDevType() {
  let type = "";
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //判断iPhone|iPad|iPod|iOS
    type = "ios";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    //判断Android
    type = "android";
  } else {
    type = "pc";
  }
  return type;
}
